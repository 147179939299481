// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-index-js": () => import("./../../../src/pages/aktualnosci/index.js" /* webpackChunkName: "component---src-pages-aktualnosci-index-js" */),
  "component---src-pages-filmy-index-js": () => import("./../../../src/pages/filmy/index.js" /* webpackChunkName: "component---src-pages-filmy-index-js" */),
  "component---src-pages-fotografia-index-js": () => import("./../../../src/pages/fotografia/index.js" /* webpackChunkName: "component---src-pages-fotografia-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-miasta-index-js": () => import("./../../../src/pages/miasta/index.js" /* webpackChunkName: "component---src-pages-miasta-index-js" */),
  "component---src-pages-wyprawy-index-js": () => import("./../../../src/pages/wyprawy/index.js" /* webpackChunkName: "component---src-pages-wyprawy-index-js" */),
  "component---src-pages-zabytkowe-index-js": () => import("./../../../src/pages/zabytkowe/index.js" /* webpackChunkName: "component---src-pages-zabytkowe-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

